<script>
	import config from 'src/config'
	import { quintInOut } from 'svelte/easing'

	let wordings = config.ctaPrefixes
	let index = 0

	setInterval(() => {
		index = (index + 1) % wordings.length
	}, 4000)

	function fadeSlideIn(node, { duration }) {
		return {
			duration,
			css: (t) => {
				const eased = quintInOut(t)

				return `
            opacity: ${t};
            transform: translateY(${(1 - t) * 20}px);
        `
			},
		}
	}

	function fadeSlideOut(node, { duration }) {
		return {
			duration,
			css: (t) => {
				const eased = quintInOut(t)
				return `
            opacity: ${t};
            transform: translateY(${(1 - t) * -20}px);
        `
			},
		}
	}

	// import { onMount } from 'svelte'

	// onMount(() => {
	// 	setInterval(() => {
	// 		console.log('yeah')
	// 		loadCallRailScript()
	// 	}, 1000)
	// })

	// // Reactive statement example
	// // $: {
	// // 	// Assuming `someReactiveVariable` is a reactive variable or store
	// // 	// This block will run whenever `someReactiveVariable` changes
	// // 	if (true) {
	// // 		loadCallRailScript()
	// // 	}
	// // }

	// function loadCallRailScript() {
	// 	// Remove any existing script to avoid duplicates
	// 	const existingScript = document.getElementById('callrail-script')
	// 	if (existingScript) {
	// 		existingScript.remove()
	// 	}

	// 	const script = document.createElement('script')
	// 	script.id = 'callrail-script'
	// 	script.type = 'text/javascript'
	// 	script.src =
	// 		'//cdn.callrail.com/companies/157547218/f9be3d89273023782a64/12/swap.js'
	// 	document.head.appendChild(script)
	// }
</script>

<div class="w-full">
	<span class="thing flex flex-shrink-0 items-center transition">
		<span class="relative mr-2 flex h-full w-full items-center">
			{#each wordings as wording, i (i)}
				{#if i === index}
					<span
						in:fadeSlideIn={{ duration: 500 }}
						out:fadeSlideOut={{ duration: 500 }}
						class="absolute right-0 w-full whitespace-nowrap text-right"
						>{wording}
					</span>
				{/if}
			{/each}
		</span>
		<!-- <a
			href="tel:{config.company.formattedPhone}"
			class="flex-1 whitespace-nowrap"
		>
			{config.company.phone}</a
		> -->
	</span>
</div>

<style>
	/* .thing {
        display: flex;
        align-items: center;
      } */

	.text-container {
		position: relative;
		line-height: 1.2em; /* Adjust based on your font size */
		overflow: hidden;
		margin-right: 0.5em; /* Space between rotating text and phone number */
	}

	.text {
		position: absolute;
		white-space: nowrap;
		width: 100%;
		left: 0;
		top: 0;
		transform: translateY(0);
	}
</style>
